.ck_Preview_Container {
    display: flex;
    align-items: center;
    .ck_Preview_Icon {
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        path {
            stroke: var(--cpd-color-text-action-accent);
            fill: var(--cpd-color-text-action-accent);
        }
    }
    .ck_Preview_Tile {
        flex: 1;
        padding-left: 10px;
        border-left: 3px solid var(--cpd-color-text-action-accent);
        border-radius: 3px;
        margin-top: 5px;
        max-width: 85%;
        .ck_Preview_Title {
            color: var(--cpd-color-text-action-accent);
            font-weight: bold;
        }
        .ck_Reply_Preview_Title {
            display: flex;
            .mx_DisambiguatedProfile_displayName {
                color: var(--cpd-color-text-action-accent);
            }
        }
    }
    .ck_Preview_Cancel {
        width: auto;
        margin-left: auto;
        margin-right: 5px;
        .ck_Preview_Box {
            position: relative;
            background-color: var(--cpd-color-bg-subtle-secondary);
            border-radius: 50%;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            .ck_Close_Button {
                background-color: var(--cpd-color-icon-tertiary);
                mask: url("@vector-im/compound-design-tokens/icons/close.svg");
                -webkit-mask: url("@vector-im/compound-design-tokens/icons/close.svg");
                -webkit-mask-repeat: no-repeat;
                mask-repeat: no-repeat;
                -webkit-mask-position: center;
                mask-position: center;
                -webkit-mask-size: 12px;
                min-width: 12px;
                min-height: 12px;
            }
        }
    }

    .ck_MFileBody_Preview {
        display: flex;
        align-items: center;
        .ck_Preview_File_Icon {
            padding-right: 5px;
        }
        .ck_Preview_File_Caption {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .ck_MFileBody_description {
        margin: 0;
        padding-bottom: 0px
    }
}
.ck_Preview {
    min-height: 65px;
}
