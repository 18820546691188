.mx_FilePanel {
    .mx_MImageBody_thumbnail_container {
        flex-wrap: wrap;
        .ck_MImageBody_image {
            position: unset;
        }
    }
    .mx_MImageBody {
        .ck_MImageBody_Preview {
            text-align: -webkit-center;
            text-align: -moz-center;
        }
    }

    h2.mx_DateSeparator_dateHeading {
        display: block;
    }

    .ck_sticky_date_container {
        display: none;
    }
    .ck_MImageBody_Error {
        .ck_EventTile_content {
            color: var(--cpd-color-text-primary);
        }
    }
}

.ck_RightPanel .mx_MFileBody_download iframe {
    color-scheme: auto;
}
