$primaryColor: var(--cpd-color-text-action-accent);
.ck_Dialog_UploadConfirm {
    .mx_Dialog {
        &:has(.ck_MessageComposer_wrapper){
            overflow: visible;
            .mx_BasicMessageComposer_input {
                color: var(--cpd-color-text-primary);
            }
            .ck_BasicMessageComposer_input {
                max-height: 300px;
            }
        }
    }
    .mx_Dialog {
        width: 350px;
        padding: 10px;
        contain: unset;
        max-height: unset;
        .mx_UploadConfirmDialog_previewOuter {
            margin: 10px 0;
            position: relative;
            overflow: hidden;
            max-width: 480px;
            max-height: 250px;

            &.ck_UploadFile_fileMode {
                text-align: left;
            }

            .mx_UploadConfirmDialog_imagePreview {
                max-height: 250px;
                vertical-align: middle;
                border-radius: 0;
                border: none;
                height: 100%;
                object-fit: contain;
            }
        }
    }

    .mx_UploadConfirmDialog_previewInner {
        position: relative;
        text-align: center;
    }

    .mx_UploadConfirmDialog_fileIcon {
        margin: 7px 5px;
        fill: #ffffff;
    }

    .ck_UploadMode {
        fill: var(--cpd-color-border-disabled);

        .active {
            fill: $primaryColor;
        }

        div {
            display: inline;
            cursor: pointer;
            &:hover {
                fill: $primaryColor;
            }

            &:first-child {
                margin-right: 10px;
            }
        }

    }

    .ck_PreviewMedia {
        transition: height 0.5s linear;
        max-height: 250px;
    }

    .ck_UploadFile_iconWrapper {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        background-color: $primaryColor;
        margin-right: 10px;
        display: inline-block;
        vertical-align: top;
        &.ck_PreviewMedia,
        .ck_PreviewMedia {
            background-color: transparent;
        }
    }

    .ck_UploadFile_Info {
        display: inline-block;

        * {
            text-align: left;
        }

        .ck_UploadFile_Name {
            max-width: 250px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    .ck_UploadFile_InfoWrapper {
        display: inline-block;
        vertical-align: middle;
    }
}

.ck_UploadMedia_Backdrop {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    filter: blur(30px);
    z-index: -1;
    object-fit: cover;
}

.ck_VideoLength {
    background-color: rgba(10, 10, 10, 80%);
    position: absolute;
    left: 5px;
    top: 5px;
    padding: 5px;
    border-radius: 10px;
    color: #fff;
}

.mx_BasicMessageComposer {
    .mx_MessageComposerFormatBar {
        // Cause we remove quote button, element hard style this by * 6, edited to * 5
        width: calc(32px * 5);
    }
}

.ck_MessageComposer_wrapper {
    $minWidth: 265px;
    $minHeight: 25px;
    $maxHeight: 175px;

    margin-top: 15px;
    .mx_SendMessageComposer {
        max-height: $maxHeight;
        .mx_BasicMessageComposer {
            .mx_BasicMessageComposer_input {
                border: none;
                resize: none;
                font-family: inherit;
                font-size: inherit;
                min-height: $minHeight;
                max-height: $maxHeight;
                min-width: $minWidth;
                max-width: 100%;
                padding: 0;
                overflow-y: auto;
            }
        }
    }
}

.mx_MessageComposer_sendMessage::before {
    background-color: #ffffff;
}

.ck_MessageComposer_wrapper {
    vertical-align: middle;
    margin: auto;
    border-top: 1px solid transparent;
    display: flex;
}

.ck_CharacterLimitDialog {
    .ck_CharacterLimitDialog_description {
        width: 100%;
        text-align: center;
    }
}

.ck_CharacterLimitCounter_container {
    width: 100%;
    text-align: center;
    margin-bottom: 1em;
}

.ck_MessageComposer_wrapper {
    .mx_UserPill,
    .ck_WysiwygComposer_Editor_container .mx_WysiwygComposer_Editor_content a[data-mention-type] {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
