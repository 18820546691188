.mx_LeftPanel_wrapper,
.mx_LeftPanel {
    &.ck_LeftPanel_list_rooms {
        --collapsedWidth: 90px;
    }
}

.mx_LeftPanel {

    .mx_LeftPanel_roomListContainer {

        .mx_LeftPanel_filterContainer {
            border-bottom: unset;
        }
    }
}

.ck_JumpToTopButton {
    z-index: 1000;
    position: absolute;
    /* 12 because height is 50 but button is only 38 = 12+(50-38) = 24 */
    bottom: 12px;
    right: 24px;
    width: 38px;
    /* give it a fixed height so the badge doesn't make */
    /* it taller and pop upwards when visible */
    height: 50px;
    text-align: center;
}

.ck_JumpToTopButton_scrollUp {
    position: relative;
    display: block;
    height: 38px;
    border-radius: 19px;
    box-sizing: border-box;
    background: $roomlist-btn-scroll-bg-color;
    border: 1.3px solid $muted-fg-color;
    cursor: pointer;

    &:hover {
        background-color: $roomlist-btn-scroll-active-bg-color;
    }
}

.ck_JumpToTopButton_scrollUp::before {
    content: "";
    position: absolute;
    inset: 0;
    mask-image: url("$(res)/img/element-icons/message/chevron-up.svg");
    mask-repeat: no-repeat;
    mask-size: 20px;
    mask-position: center 6px;
    background: $muted-fg-color;
}
