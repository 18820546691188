.invalidText{
    color: var(--cpd-color-text-critical-primary);
    font-size: $font-13px;
}

.errorText {
    color: var(--cpd-color-text-critical-primary);
    font-size: $font-15px;
    margin-top: 15px;
}

.ck_input_ip_address_disabled {
    color: inherit;
}
