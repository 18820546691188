.ck_SetupGoogleAuthenticator {
    border-radius: 10px;
    padding: 15px;
    .subheading {
        font-size: $font-16px;
        display: block;
        font-weight: var(--cpd-font-weight-semibold);
        margin-top: 12px;
        text-align: center;
        padding-bottom: 13px;
        width: 100%;
    }

    .MuiStepIcon-text {
        font-size: $font-15px;
    }

    .MuiStepIcon-root {
        circle {
            color: #A9B2BC;
        }

        &.Mui-active {
            circle {
                color: var(--cpd-color-text-action-accent) !important;
            }
        }
    }

    .MuiStepLabel-alternativeLabel {
        color: #A9B2BC;

        &.Mui-completed,
        &.Mui-active {
            color: var(--cpd-color-text-action-accent);;
        }
    }

    .MuiStepIcon-root.Mui-completed {
        color: var(--cpd-color-text-action-accent);
    }

    .step-label {
        font-size: $font-10px;
        width: 100%;
    }

    .btn-previous {
        margin-right: 5px;
        background-color: var(--cpd-color-bg-canvas-default);
    }

    .step-content {
        width: 100%;
        min-height: 316px;
        &_1st {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin-bottom: 1rem;

            .qr-code {
                display: flex;
                justify-content: center;
                background: #fff;
                box-shadow: rgb(24 26 32 / 10%) 0 0 1px, rgb(71 77 87 / 4%) 0 3px 6px, rgb(24 26 32 / 4%) 0 1px 2px;
                border-radius: 4px;
                width: 230px;
                height: 230px;
                padding: 8px;
                text-align: center;
                overflow: hidden;
                align-items: center;
                .icon-notes{
                    width: 100%;
                    height: 100%;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 55%;
                    /*noinspection CssUnknownTarget*/
                    background-image: url('$(web_res)/themes/element/icons/notes.svg');
                }
            }

            .enter-auth-code {
                width: 50%;
            }

            .mx_E2EIcon_verified {
                width: 50px;
                height: 50px;
            }
        }

        .content-box-info {
            text-align: center;
            line-height: 2rem;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .content-tabs {
                label {
                    cursor: pointer;
                    font-weight: 500;
                }

                label.active {
                    border-bottom: 2px solid;
                }
            }

            &__text-scan{
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .tip-info {
                max-width: 350px;
            }
        }

        .tip-download-info {
            font-size: $font-14px;
            font-style: italic;
        }

        .mx_Login_loader {
            top: unset;
            left: unset;
        }

        .mx_Field {
            margin-bottom: 0;
        }

        .verify-error-text {
            color: var(--cpd-color-text-critical-primary);
            font-size: $font-12px;
            height: 1.5rem;
            text-align: center;
            padding-top: 0.3rem;
        }
    }
}
