.mx_SettingsTab {
    .ck_EmailAddresses {
        margin-bottom: 30px;
    }
    .mx_GeneralUserSettingsTab_section--discovery_existing {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .ck_UserProfileSettings_profile {
        input {
            width: 350px;
            max-width: 350px;
        }
    }
    .ck_UserProfileSettings_profileAvatar {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .ck_UserProfileSettings_profile_username {
        margin-top: 10px;
    }

    .mx_EmailAddresses_new,
    .mx_EmailAddresses_verify,
    .mx_PhoneNumbers_new,
    .mx_PhoneNumbers_verify {
        margin: 10px 0;
    }

    .ck_PhoneNumbers_activeFlowRemove {
        margin-top: 20px;
    }

    .mx_ExistingPhoneNumber_confirmBtn {
        padding-left: 0;
    }

    .ck_ThreePID_error {
        color: var(--cpd-color-text-critical-primary);
        margin-bottom: 10px;
    }

    .mx_Field .ck_CountryDropdown {
        width: 9rem;
    }
}

.ck_SettingsSubsection_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}
