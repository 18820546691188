/*
Copyright 2024 New Vector Ltd.
Copyright 2016 OpenMarket Ltd

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_TopUnreadMessagesBar {
    z-index: 1000;
    position: absolute;
    top: 24px;
    right: 24px;
    width: 38px;
}

.mx_TopUnreadMessagesBar::after {
    content: "";
    position: absolute;
    top: -8px;
    left: 11px;
    width: 4px;
    height: 4px;
    border-radius: 16px;
    background-color: #ffffff;
    border: 6px solid var(--cpd-color-green-ctalk);
    pointer-events: none;
}

.mx_TopUnreadMessagesBar_scrollUp {
    height: 38px;
    border-radius: 19px;
    box-sizing: border-box;
    background: $background;
    border: 1.3px solid var(--cpd-color-icon-tertiary);
    cursor: pointer;
}

.mx_TopUnreadMessagesBar_scrollUp::before {
    content: "";
    position: absolute;
    width: 36px;
    height: 36px;
    /*noinspection CssUnknownTarget*/
    mask-image: url("~matrix-react-sdk/res/img/element-icons/message/chevron-up.svg");
    mask-repeat: no-repeat;
    mask-size: 20px;
    mask-position: center;
    background: var(--cpd-color-icon-tertiary);
}

.mx_TopUnreadMessagesBar_markAsRead {
    display: block;
    width: 18px;
    height: 18px;
    background: $background;
    border: 1.3px solid var(--cpd-color-icon-tertiary);
    border-radius: 10px;
    margin: 5px auto;
}

.mx_TopUnreadMessagesBar_markAsRead::before {
    content: "";
    position: absolute;
    width: 18px;
    height: 18px;
    mask-image: url("@vector-im/compound-design-tokens/icons/close.svg");
    mask-repeat: no-repeat;
    mask-size: 16px;
    mask-position: center;
    background: var(--cpd-color-icon-tertiary);
}
