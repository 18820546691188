@import "../../../node_modules/matrix-react-sdk/res/css/_font-sizes.pcss";

.mx_ReplyTile {
    .mx_EventTile_content {
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        /*noinspection CssUnknownProperty*/
        overflow: hidden;
        padding: 0 !important;
        line-height: 1.5em;
        p, div, h1, h2, h3, h4, h5, h6, blockquote, table, ul, ol, dd, dl, dt, pre {
            display: inline;
            &::after {
                white-space: pre;
            }
        }
        .mx_EventTile_body {
            * {
                font-weight: normal;
            }
        }
        .markdown-body h1 {
            padding-bottom: unset;
        }
    }
    .ck_EventTile_content {
        width: 100%
    }
    .ck_EventTile_content p {
        display: none !important;

    }
    .ck_EventTile_content blockquote p:nth-last-child(2) {
        display: inline !important;
    }
    .ck_PreviewReply_Text {
        max-height: 45px;
        &.mx_EventTile_content {
            -webkit-line-clamp: 1;
            .mx_EventTile_body {
                white-space: nowrap !important; /* Due to this attribute in library set important */
                text-overflow: ellipsis;
                overflow: hidden;
                display: block;
            }
        }
    }
}

.mx_EventTile_content {
   padding: 5px 0;
}

.mx_EventTile_content {
    .markdown-body {
        code.ck_message_format {
            background-color: unset;
            border: unset;
            border-radius: unset;
            padding: unset;
            line-height: unset;
            margin-right: unset;
            &::before,
            &::after {
                letter-spacing: unset;
                content: unset;
            }
        }
    }
}
