.ck_SetupYubiKey {
    border-radius: 10px;
    padding: 15px;

    .subheading {
        font-size: $font-16px;
        display: block;
        font-weight: var(--cpd-font-weight-semibold);
        margin-top: 12px;
        text-align: center;
        padding-bottom: 13px;
        width: 100%;
    }

    &_dialogContent {
        max-width: 320px;
        &-loading {
            width: 100%;
            display: flex;
            justify-content: center;
        }
        .btnBack {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 1rem;
        }
        .error-message {
            color: var(--cpd-color-text-critical-primary);
            text-align: center;
        }
        .ck_Verify_OTPTimer {
            margin-top: 1rem;
            font-size: $font-14px;
            text-align: center;
        }
    }

    .mx_Dialog_buttons {
        text-align: center;
    }

    .step-content {
        .step-content_2nd {
            display: flex;
            justify-content: center;
        }
        .mx_E2EIcon {
            width: 39px;
            height: 39px;
        }
        .ck_SetupYubiKey {
            &__box {
                max-width: 279px;
                font-size: $font-12px;
                background-color: unset;
                border-radius: 4px;
                padding: 25px;
                min-height: unset;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: space-between;

                &-title {
                    text-align: center;
                    font-size: $font-20px;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    &-icon {
                        width: 79px;
                        height: 79px;
                        border: 1px solid #9c9c9c;
                        border-radius: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        span {
                            width: 68px;
                            height: 68px;
                        }
                    }
                    &-userInfoLogin {
                        padding: 10px 0;
                    }
                }

                &-body {
                    .box-body__list{
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        height: 100%;
                        font-size: $font-13px;

                        .box-body__item {
                            padding: 10px 0;

                            &-number {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                background-color: var(--cpd-color-text-action-accent);
                                color: rgb(255, 255, 255);
                                width: 20px;
                                height: 20px;
                                border-radius: 50%;
                                margin-right: 10px;
                                float: left;
                            }

                            &-detail {
                                max-width: 239px;
                                float: left;
                            }

                            &.icon__connect-yubikey {
                                display: flex;
                                align-items: center;
                                flex-direction: row;
                                justify-content: center;
                                position: relative;

                                .yubikey-device {
                                    width: 39px;
                                    min-height: 39px;
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    background-size: 100%;
                                    /*noinspection CssUnknownTarget*/
                                    background-image: url('$(web_res)/themes/element/icons/yubikey-disabled.svg');
                                    position: absolute;
                                    bottom: 39px;
                                    left: 10px;
                                    &.active {
                                        /*noinspection CssUnknownTarget*/
                                        background-image: url('$(web_res)/themes/element/icons/yubikey.svg');
                                    }
                                }

                                .laptop {
                                    width: 179px;
                                    min-height: 179px;
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    background-size: 100%;
                                    /*noinspection CssUnknownTarget*/
                                    background-image: url('$(web_res)/themes/element/icons/laptop-disabled.svg');
                                    &.active {
                                        /*noinspection CssUnknownTarget*/
                                        background-image: url('$(web_res)/themes/element/icons/laptop.svg');
                                        position: relative;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        .verifyYubikeyBusy {
                                            position: absolute;
                                        }
                                    }
                                }
                            }
                        }
                    }

                }

                &-footer {
                    .input-totp {
                        height: 0;
                        overflow: hidden;
                        border: none;
                    }
                    .error-message {
                        color: var(--cpd-color-text-critical-primary);
                        text-align: center;
                    }
                }
            }
        }
    }

}
