.ck_AddBotToRoomDialog {
    min-height: 400px;
}
.ck_AddBotToRoomDialog_searchLine {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.ck_AddBotToRoomDialog_searchAction {
    margin-left: 5px;
}
.ck_AddBotToRoomDialog_botInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: solid 1px var(--cpd-color-gray-600);
    border-radius: 8px;
    padding: 12px;
    margin-top: 15px;
}
.ck_AddBotToRoomDialog_botDetails {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.ck_AddBotToRoomDialog_botText {
    color: var(--cpd-color-text-primary);
    margin-left: 15px;
}
.ck_AddBotToRoomDialog_botName {
    font-size: $font-16px;
    font-weight: var(--cpd-font-weight-semibold);
}
.ck_AddBotToRoomDialog_botDescription {
    font-size: $font-14px;
    font-weight: 400;
}
