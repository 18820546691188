.ck_errorDialog {
    max-width: 350px;
    .mx_Dialog_content {
        margin: 0;
    }
    .mx_Dialog_buttons {
        .btn-none-border {
            border: none !important;
        }
    }
}
