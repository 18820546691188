.ck_AssignAdminRoom_Dropdown {
    margin: 0 0 50px;
    li {
        list-style: none;
    }
}

.ck_Dropdown_menu_AssignUserAdmin .mx_Dropdown_menu {
    max-height: 100px;
}

.ck_Dialog_fixedWidth_AssignUserAdmin {
    max-width: 500px;
}

.ck_Dialog_content_AssignUserAdmin {
    margin-bottom: 25px;
    color: var(--cpd-color-text-critical-primary);
}
