
.ck_Slider {
    position: relative;
    margin: 0;
    flex-grow: 1;

    input[type="range"] {
        height: 2.4em;
        appearance: none;
        width: 100%;
        background: none;
        font-size: 1em; /* set base multiplier for em units applied later */

        --active-color: $accent;
        --selection-dot-size: 2.4em;

        &:disabled {
            cursor: not-allowed;

            --active-color: $slider-background-color;
        }

        &:focus:not(:focus-visible) {
            outline: none;
        }

        &::-webkit-slider-runnable-track {
            width: 100%;
            height: 0.4em;
            background: $slider-background-color;
            border-radius: 5px;
            border: 0 solid #000000;
        }
        &::-webkit-slider-thumb {
            border: 0 solid #000000;
            width: var(--selection-dot-size);
            height: var(--selection-dot-size);
            background: var(--active-color);
            border-radius: 50%;
            -webkit-appearance: none;
            margin-top: calc(2px + 1.2em - var(--selection-dot-size));
        }
        &:focus::-webkit-slider-runnable-track {
            background: $slider-background-color;
        }

        &::-moz-range-track {
            width: 100%;
            height: 0.4em;
            background: $slider-background-color;
            border-radius: 5px;
            border: 0 solid #000000;
        }
        &::-moz-range-progress {
            height: 0.4em;
            background: var(--active-color);
            border-radius: 5px;
            border: 0 solid #000000;
        }
        &::-moz-range-thumb {
            border: 0 solid #000000;
            width: var(--selection-dot-size);
            height: var(--selection-dot-size);
            background: var(--active-color);
            border-radius: 50%;
        }

        &::-ms-track {
            width: 100%;
            height: 0.4em;
            background: transparent;
            border-color: transparent;
            color: transparent;
        }
        &::-ms-fill-lower,
        &::-ms-fill-upper {
            background: $slider-background-color;
            border: 0 solid #000000;
            border-radius: 10px;
        }
        &::-ms-thumb {
            margin-top: 1px;
            width: var(--selection-dot-size);
            height: var(--selection-dot-size);
            background: var(--active-color);
            border-radius: 50%;
        }
        &:focus::-ms-fill-upper {
            background: $slider-background-color;
        }
        &::-ms-fill-lower,
        &:focus::-ms-fill-lower {
            background: var(--active-color);
        }
    }

    output {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        font-size: 1em; /* set base multiplier for em units applied later */
        text-align: center;
        top: 3em;

        .ck_Slider_selection_label {
            color: $muted-fg-color;
            font: var(--cpd-font-body-md-regular);
        }
    }
}
