.ck_PinnedEventTile {
    &.mx_PinnedEventTile {
        // padding-left: 10px;
        width: unset;
        align-items: center;
    }

    &:has(.mx_MImageBody, .mx_MVideoBody, .mx_MFileBody, .mx_MVoiceMessageBody) {
        .mx_ReplyChain_wrapper {
            padding-bottom: 10px;
        }
    }

    .mx_PinnedEventTile_wrapper {
        width: 100%;
    }

    .mx_PinnedEventTile_footer {
        .ck_Group_MessageTimestamp {
            /*noinspection CssUnresolvedCustomProperty*/
            color: var(--MessageTimestamp-color);
            font-size: .625rem;
            display: inline-flex;
            align-items: center;
            gap: 5px;
        }
    }
}

.ck_PinnedEventTile_box {
    grid-area: name;
    display: flex;
    overflow: hidden;

    .ck_PinnedEventTile_senderWrapper {
        max-width: 100%;
        display: flex;
        align-items: center;
        .mx_PinnedEventTile_senderAvatar {
            min-width: var(--cpd-avatar-size);
        }
    }
}

.mx_RightPanel_ResizeWrapper {
    .mx_PinnedEventTile_message {
        .ck_MessageEvent_forward {
            padding: 10px 0;
        }

        .ck_MVideoBody_description {
            min-width: 320px;
            max-width: 320px;
        }

        .mx_MImageReplyBody_info {
            max-width: calc(100% - 80px);
        }
    }
}

.ck_PinnedEventTile_sender {
    margin-left: 10px;
    font-weight: var(--cpd-font-weight-semibold);
    font-size: $font-15px;
    line-height: 2.4rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.ck_PinnedEventTile_order {
    grid-area: order;
    font-weight: var(--cpd-font-weight-semibold);
    font-size: $font-15px;
    line-height: 2.4rem;
    color: var(--cpd-color-text-link-external);
}

.mx_PinnedEventTile_footer {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mx_PinnedMessagesCard_wrapper_unpin_all {
    padding-right: 5px;
}

.mx_PinnedEventTile_message {
    .mx_MImageBody {
        .ck_MImageBody_Preview {
            text-align: -webkit-center;
            text-align: -moz-center;
        }
    }

    // Disable click to event reply into pinned

    .mx_ReplyTile {
        pointer-events: none;
    }
}
