.ck_LockedPasscode {
    &:before {
        content: '';
        width: 100%;
        height: 27px;
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        /*noinspection CssUnknownProperty*/
        -webkit-app-region: drag;
    }
    display: flex;
    flex-direction: column;
    .ck_LockedPasscode_Title {
        font-weight: 500;
        font-size: $font-20px;
        margin: 0;
    }
    .ck_LockedPasscode_form {
        flex: 1;
        margin: 250px auto;
        position: relative;
        label {
            display: none;
        }
        .mx_Field {
            min-width: 250px;
        }

        .mx_AccessibleButton {
            width: 100%;
        }
    }
    .ck_LockedPasscode_formControls {
        display: flex;
        .mx_AccessibleButton {
            cursor: pointer;
            margin: 1.25em 5px;
            position: relative;
            width: 32px;
            height: 32px;
            border-radius: 100%;
            &:before {
                position: absolute;
                height: 16px;
                width: 16px;
                top: 8px;
                left: 9px;

                mask-image: url("@vector-im/compound-design-tokens/icons/arrow-right.svg");
                mask-repeat: no-repeat;
                mask-size: contain;
                mask-position: center;

                background-color: var(--cpd-color-text-primary);
                content: "";
            }
        }
    }

    .ck_LocalPasscodePanel_Error {
        text-align: center;
    }

    .ck_LockedPasscode_actions {
        margin: 0 auto 50px;
        z-index: 1;
    }
}
