.mx_WhitelistedIPsRoomSettingsTab {
    * {
        font-size: unset !important;
    }
    .mx_WhiteListedIPs_item_action {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }
    .mx_AddWhiteListedIPs_button {
        width: 100px;
    }
    .mx_WhiteListedIPs-loading {
        margin-top: 20px;
        align-self: center;
    }
    .mx_SearchBox {
        margin: unset;
    }
    .mx_textinput {
        max-width: 50%
    }
}

.mx_DialogActionWhitelist {
    width: 400px;
    overflow-wrap: break-word;
}

.mx_DialogButtonDelete {
    background-color: var(--cpd-color-bg-critical-primary) !important;
}

.ck_search_add_btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
}

.ck_toggle_enable {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // width: 25%;
    align-items: center;
}

.ck_input_required {
    label:after {
        content: ' *';
        color: var(--cpd-color-text-critical-primary);
    }
}
