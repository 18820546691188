.ck_MPollBody_reply {
    width: unset;
    max-width: 100%;
}

.ck_MPollBody_title_reply {
    text-overflow: ellipsis;
    overflow-y: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    &::before {
        content: "";
        position: relative;
        display: inline-block;
        margin-right: 12px;
        top: 3px;
        left: 3px;
        height: 20px;
        width: 20px;
        background-color: var(--cpd-color-text-secondary);
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-size: contain;
        mask-size: contain;
        -webkit-mask-position: center;
        mask-position: center;
        -webkit-mask-image: url(~matrix-react-sdk/res/img/element-icons/room/composer/poll.svg);
        mask-image: url(~matrix-react-sdk/res/img/element-icons/room/composer/poll.svg);
    }
}
