.mx_SettingsTab {
    form.ck_LayoutSwitcher_LayoutSelector {
        flex-direction: row;
    }
}
.mx_LayoutSwitcher_LayoutSelector {
    .mxLayoutSwitcher_LayoutSelector_LayoutRadio {
        width: 50%;
        max-width: 50%;
        .mxLayoutSwitcher_LayoutSelector_LayoutRadio_EventTilePreview {
            .mx_EventTile[data-layout="bubble"] {
                margin: var(--cpd-space-4x) var(--cpd-space-7x) var(--cpd-space-4x) var(--cpd-space-6x);
            }
        }
    }
}
