.ck_LinksPanelCard_wrapper {
    height: 100%;
    padding: var(--cpd-space-4x);
}

.ck_LinkPanel_Empty {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
}

.ck_LinkEventTile_top {
    font-weight: bold;
}

.ck_LinkEventTile_links {
    margin: 10px 0 0;
}

.ck_LinkEventTile_footer {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: .625rem;
    line-height: 12px;
}

.ck_LinkEventTile {
    padding-bottom: 20px;

    &:not(:first-child) {
        margin-top: 20px;
    }

    &:not(:last-child) {
        border-bottom: solid 1px var(--cpd-color-gray-400);
    }
}

.ck_LinkEventTile_body {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    padding-top: 5px;
}

.ck_LinkEventTile_link {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
}
