.mx_RightPanel {
    .ck_FullWidth_btn {
        width: calc(100% - var(--cpd-space-4x) - 32px);
    }
    .ck_UnpinAll_btn {
        margin: 5px 10px;
        svg {
            width: 16px;
            height: 16px;
            display: inline-block;
            margin-right: 3px;
            path {
                fill: var(--cpd-color-text-primary);
            }
        }
    }
    .mx_PinnedMessagesCard {
        order: 2;
        flex: 1 1 0;
        .mx_AutoHideScrollbar {
            flex: 1 1 0;
        }
    }
}
.mx_EventTile_msgOption {
    width: unset !important;
}

.mx_EventTile_msgOption {
    -webkit-margin-end: unset !important;
    margin-inline-end: unset !important;
}

.ck_PinnedIcon {
    transform: scaleX(-1);
    width: 0.675rem;
    margin: 0 2px;
    display: inline-block;
}

.mx_EventTile[data-layout="group"] {
    .mx_EventTile_line {
        .ck_PinnedIcon {
            position: absolute;
            left: -5px;
        }
    }
}

.ck_RoomHeader_pinnedContainer {
    $heightTile: 42px;
    background-color: var(--cpd-color-bg-canvas-default);
    padding-left: 30px;
    border-bottom: 1px solid var(--cpd-color-alpha-gray-400);
    min-height: $heightTile;

    .ck_RoomHeader_pinnedWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
    }
    .ck_RoomHeader_pinnedBorderContainer {
        flex: 0;
        width: 2px;
        min-width: 2px;
        height: $heightTile;
        max-height: $heightTile;
        overflow: hidden;
        position: relative;
    }
    .ck_RoomHeader_pinnedBorder {
        position: absolute;
        bottom: 0;
        display: flex;
        flex-direction: column;
        transition: bottom 250ms linear;
        span {
            display: inline-block;
            margin-top: 2px;
            width: 2px;
            /*noinspection CssUnresolvedCustomProperty*/
            height: var(--pinnedBorderHeight);
            opacity: 50%;
            background-color: var(--cpd-color-text-action-accent);
            &.active {
                opacity: 1;
            }
            &:first-child {
                margin: 0;
            }
        }
    }
    .ck_RoomHeader_pinnedContent {
        flex: 1;
        padding-left: 10px;
        max-height: $heightTile;
        min-height: $heightTile;
        position: relative;
        overflow: hidden;
    }
    .ck_RoomHeader_pinnedContentWrapper {
        position: absolute;
        bottom: 0;
        transition: bottom 250ms linear;
    }
    .ck_RoomHeader_pinnedEventTile {
        display: flex;
        max-height: $heightTile;
    }
    .ck_PinnedPreview {
        font-size: 32px;
        margin-right: 10px;
    }
    .ck_PinnedPreview_video {
        img {
            border-radius: 8px;
            width: 40px;
            height: 40px;
            object-fit: cover;
        }
    }
    .ck_PinnedPreview_poll {
        svg {
            color: var(--cpd-color-text-secondary);
            width: 24px;
            height: 24px;
        }
    }
    .ck_RoomHeader_pinnedTitle {
        color: var(--cpd-color-text-action-accent);
        font-weight: 500;
    }
    .ck_RoomHeader_pinnedTileContent {
        width: 100%;
        & > div {
            max-height: 22px;
            min-height: 22px;
        }
    }
    .ck_RoomHeader_pinnedMessage {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        .mx_Emoji {
            font-size: $font-15px;
        }
    }
    .ck_PinnedPreview_image {
        .mx_MImageBody_thumbnail {
            width: 40px;
            height: 40px;
            object-fit: contain;
        }
    }
    .ck_PinnedPreview_imagePlaceholder {
        svg {
            width: 40px;
            height: 40px;
        }
    }
    .mx_MImageBody_placeholder {
        width: 40px;
        .mx_Blurhash {
            max-width: 40px;
        }
    }
    .ck_RoomHeader_pinnedList_Icon {
        .mx_AccessibleButton {
            width: 18px;
            padding: 10px 20px;
            color: var(--cpd-color-text-action-accent);
        }
        &.active, .mx_AccessibleButton:hover {
            svg > path:nth-child(2) {
                fill: var(--cpd-color-text-action-accent);
            }
        }
    }
}
