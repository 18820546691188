.mx_MVoiceMessageBody {
    .mx_MediaBody.mx_VoiceMessagePrimaryContainer {
        background-color: unset;
        padding-bottom: 6px;
        margin-bottom: 10px;
    }
}

.mx_EventTile {
    &[data-layout="bubble"] .mx_EventTile_line:has(.mx_MVoiceMessageBody .mx_MediaBody.mx_VoiceMessagePrimaryContainer) {
        background-color: var(--backgroundColor);
    }
}
