
.mx_RoomSummaryCard {
    .ck_common_rooms {
        svg {
            fill: var(--cpd-color-icon-primary);
        }
    }
}

.ck_common_rooms_card {
    align-items: center;

    .mx_BaseCard_header {
        width: 100%;
    }

    .mx_RoomTile_titleContainer {
        padding-left: 10px;
    }

    .ck_common_rooms_search {
        background-color: var(--cpd-color-theme-bg);
        position: sticky;
        top: 0;
        z-index: 1;
        padding: 1px 0;
    }
}
