.mx_AutoHideScrollbar {
    overflow-y: auto;
}

.ck_TimelineSeparator_container {
    top: 0;
    z-index: 10;
    padding: 5px;
    display: flex;
    justify-content: center;
}

.ck_TimelineSeparator_container_sticky {
    position: sticky;
}

.ck_sticky_date_container {
    position: absolute;
}

.ck_DateSeparator_dateContent {
    min-width: 40px;
    background: var(--cpd-color-sticky-date-background);
    border-radius: 1.5em;
    min-height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    .mx_DateSeparator_dateContent {
        padding: 4px 10px;
    }
}

.mx_DateSeparator_dateHeading {
    color: var(--cpd-color-sticky-date-text);
}
