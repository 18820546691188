.ck_Form_Body {
    width: 100%;
    .ck_Form_Action {
        padding: .5em 0;
    }
    .ck_Form_Body_status {
        color: var(--MessageTimestamp-color);
        .ck_Form_Body_status_item {
            position: absolute;
            right: 15px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .ck_Form_error_icon {
                color: var(--cpd-color-red-900);
                padding-right: .2em;
            }
        }
    }
    .ck_Form_failed:hover {
        cursor: default;
    }
}

.ck_Form {
    padding-top: 1em;
    .ck_Form_label {
        font-weight: bold;
    }
}

.ck_Form_Info {
    padding-top: 20px;
    .ck_Form_Info_From {
        color: var(--cpd-color-green-ctalk);
    }
    .ck_Form_Info_Description {
        padding: .5em 0;
        white-space: pre-line;
    }
    .ck_Form_Info_Download_File {
        display: flex;
        background: var(--cpd-color-gray-400);
        min-width: 250px;
        min-height: 50px;
        align-items: center;
        justify-content: space-between;
        border: none;
        border-radius: .8em;
        padding: 0 1em;
        margin: .5em 0;
        cursor: pointer;
        font-size: 1em;
        .ck_Form_Info_Download_File_Type {
            color: var(--cpd-color-green-ctalk);
        }
    }
}

.ck_Form_icon {
    padding: 0 0.3em;
    fill: var(--cpd-color-green-ctalk);
    path {
        fill: var(--cpd-color-green-ctalk);
    }
}

.ck_Form_icon_retry {
    padding: 0 0.3em;
}

.ck_time_expire {
    display: flex;
    align-items: center;
    padding: .3em 0;
    .ck_time_expire_icon {
        padding: 0 .2em;
        fill: var(--MessageTimestamp-color);
    }
    .ck_time_expire_warning_icon {
        padding: 0 .3em;
        color: #fbce00;
    }
    .ck_time_expire_info {
        color: var(--MessageTimestamp-color);
    }
}
.mx_TextWithTooltip_target {
    .ck_time_expire:hover {
        cursor: default;
    }
}


