$contentWidth: 500px;

.ck_LockedScreen {
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .ck_LockedScreen_Title {
        font-weight: normal;
        font-size: 1.1rem;
        margin-bottom: 30px;
    }

    .ck_LockedScreen_content {
        max-width: $contentWidth;
        margin: 0 auto;
        margin-top: 250px;
        z-index: 1;
        .mx_AccessibleButton {
            font-weight: unset;
            width: 80%;
            margin-bottom: 10px;
        }
    }

    .ck_LockedScreen_actions {
        margin-bottom: 50px;
        .mx_AccessibleButton {
        }
    }
}
