.mx_LegacyCallEvent_wrapper {
    .mx_LegacyCallEvent {
        .mx_LegacyCallEvent_content {
            .mx_LegacyCallEvent_content_button {
                span::before {
                    background-color: #ffffff;
                }
            }
        }
    }

    .mx_LegacyCallEvent_info {
        .mx_BaseAvatar {
            /*noinspection CssUnresolvedCustomProperty*/
            min-width: var(--cpd-avatar-size);
        }
    }
}

.mx_IncomingLegacyCallToast {
    .mx_IncomingLegacyCallToast_content {
        .mx_IncomingLegacyCallToast_buttons {
            .mx_IncomingLegacyCallToast_button {
                span::before {
                    background-color: #ffffff;
                }
            }
        }
    }
}

// Improve UX/UI call Turn in layout Bubble
.mx_EventTile[data-layout="bubble"] {
    .mx_LegacyCallEvent_wrapper {
        margin-bottom: -10px;

        .mx_LegacyCallEvent {
            padding: unset;
            border-style: unset;
            border-width: unset;
            min-width: 230px;
            width: 100%;
        }
    }

    .mx_LegacyCallEvent_info {
        display: none;
    }

    &:has(.ck_LegacyCallEvent_ringing) {
        .mx_LegacyCallEvent {
            min-width: 260px;

            &.mx_LegacyCallEvent_narrow {
                .mx_LegacyCallEvent_iconButton {
                    top: 6px;
                }
            }
        }

        .mx_LegacyCallEvent_content {
            margin-inline-start: unset;
        }
    }

    &:has(.ck_LegacyCallEvent_reason) {
        .mx_LegacyCallEvent_content {
            font-size: var(--cpd-font-size-root);
            color: var(--cpd-color-text-primary);
            margin-inline-start: unset;
            gap: unset;
            flex-wrap: unset;
            max-width: 100%;
            justify-content: space-between;

            .ck_LegacyCallEvent_reason {
                display: flex;
                align-items: center;
            }

            .ck_LegacyCallEvent_status_icon {
                padding-right: 10px;
                -webkit-mask-repeat: no-repeat;
                mask-repeat: no-repeat;
                -webkit-mask-position: center;
                mask-position: center;
                -webkit-mask-size: contain;
                mask-size: contain;
                width: 16px;
                height: 16px;
                /*noinspection CssUnknownTarget*/
                mask-image: url('$(web_res)/themes/element/icons/outcoming-call.svg');

                &_incoming {
                    /*noinspection CssUnknownTarget*/
                    mask-image: url('$(web_res)/themes/element/icons/incoming-call.svg');
                }

                background: var(--cpd-color-text-critical-primary);

                &_success {
                    background: var(--cpd-color-text-action-accent);
                }
            }

            .ck_LegacyCallEvent_contain_duration {
                display: flex;
                flex-direction: column;
                margin-left: 5px;

                .duration {
                    margin-top: 5px;
                    font-size: 12px;
                    color: var(--cpd-color-text-secondary);
                }
            }

            .mx_LegacyCallEvent_content_button {
                background-color: unset;
                border: unset;

                span {
                    font-size: 0;

                    &::before {
                        background-color: var(--cpd-color-text-action-accent);
                    }
                }
            }

            .mx_InfoTooltip_icon {
                &::before {
                    vertical-align: bottom;
                }
            }
        }
    }

    &:has(.ck_LegacyCallEvent_connected, .ck_LegacyCallEvent_connecting) {
        .mx_LegacyCallEvent {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
        }

        .mx_LegacyCallEvent_info {
            display: unset;

            .mx_BaseAvatar {
                display: none;
            }

            .mx_LegacyCallEvent_sender {
                display: none;
            }

            .mx_LegacyCallEvent_type {
                color: var(--cpd-color-text-primary);
            }
        }
    }
}
