.mx_AuthPage_modalContent {
    .ck_SetupGoogleAuthenticator {
        background: #ffffff;
    }
}
.mx_AuthBody {
    font-size: $font-15px;
    .mx_SSOButtons {
        margin-top: 15px;
    }
}
.mx_AccessibleButton {
    &.mx_AccessibleButton_hasKind {
        &.ck_QRLogin_btn {
            width: 100%;
            text-transform: uppercase;
            font-weight: 500;
        }
    }
}
