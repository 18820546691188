@import "matrix-react-sdk/res/css/_font-sizes.pcss";
@import "_paths.pcss";
@import "matrix-react-sdk/res/themes/light/css/_fonts.pcss";
@import "_light.pcss";
@import "matrix-react-sdk/res/themes/light/css/_mods.pcss";
@import "../_components.pcss";
@import url("highlight.js/styles/atom-one-light.min.css");
@import url("github-markdown-css/github-markdown-light.css");

@import "../../vector-im/compound-design-tokens/assets/web/css/compound-design-tokens.css";
// CTalk component
@import "../component.pcss";
