@define-mixin addBackground {
    background-color: var(--cpd-color-bg-canvas-default);
    @mixin-content;
}

@define-mixin addChatBg {
    background-color: var(--cpd-color-bg-subtle-secondary);
    @mixin-content;
}

@define-mixin addRoundedBg $padding: 3px 8px, $borderRadius 15px {
    background-color: var(--cpd-color-bg-subtle-secondary);
    border-radius: $borderRadius;
    padding: $padding;
    color: var(--cpd-color-text-primary);
    @mixin-content;
}

@define-mixin addBtnBackdrop {
    position: relative;
    z-index: 1;
    $paddingY: 1px;
    $paddingX: 10px;
    color: var(--cpd-color-green-1100);

    &::after {
        content: '';
        display: block;
        position: absolute;
        left: -$paddingX;
        right: -$paddingX;
        top: -$paddingY;
        bottom: -$paddingY;
        background-color: var(--cpd-color-bg-subtle-secondary);
        opacity: 60%;
        border-radius: 15px;
        z-index: -1;
        padding: $paddingY $paddingX;
    }
}

@define-mixin addBackdrop $opacity: 70%, $rounded: 15px, $padding: 4px 5px {
    position: relative;
    z-index: 1;
    padding: $padding;
    color: var(--cpd-color-text-primary);
    &:before {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        bottom: 0;
        z-index: -1;
        right: 0;
        background-color: var(--cpd-color-bg-subtle-secondary);
        opacity: $opacity;
        border-radius: $rounded;
    }
    @mixin-content;
}
// MatrixChat override
.mx_MatrixChat {
    & > .ck_RoomView_wrapper {
        &:not(.mx_LeftPanel):not(.mx_SpacePanel):not(.mx_ResizeHandle):not(.mx_LeftPanel_outerWrapper) {
            background: none;
        }

        .mx_MessageComposer {
            @mixin addBackground;
            max-width: none;
        }
    }
    .mx_RoomView:before {
        background-color: var(--cpd-color-bg-canvas-default);
    }

    .mx_LeftPanel_wrapper {
        @mixin addBackground;
    }

    .mx_NewRoomIntro {
        @mixin addBackdrop 70%, 8px;
        padding: 15px;
        margin-right: 48px;

        & > p {
            color: var(--cpd-color-text-primary);
        }
    }

    .mx_WhoIsTypingTile {
        .mx_WhoIsTypingTile_avatars {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .mx_WhoIsTypingTile_label {
            font-weight: var(--cpd-font-weight-medium);
            font-size: $font-13px;
            color: var(--cpd-color-green-1000);
        }
    }
    .mx_GenericEventListSummary[data-layout="bubble"] {
        .mx_GenericEventListSummary_toggle {
            white-space: nowrap;
        }
    }
}

// MatrixChat with chat backgrounds
.mx_MatrixChat--has-background {
    .ck_LockedScreen {
        position: relative;
        .ck_LockedScreen_content {
            @mixin addBackdrop 85%, 5px, 20px;
            z-index: 1;
        }
    }

    .ck_LockedPasscode_form {
        form {
            @mixin addBackdrop 85%, 5px;
            padding: 20px;
        }

        .mx_Field {
            input {
                background: none;
            }
        }
    }

    .ck_LockedPasscode_actions,
    .ck_LockedScreen_actions {
        @mixin addRoundedBg;
        border-radius: 15px;
        padding: 4px 15px;
        z-index: 1;

        .mx_AccessibleButton {
            font-weight: 500;
        }
    }

    .mx_SearchWarning {
        background-color: var(--cpd-color-bg-canvas-default);
    }
    // Something went wrong error
    .mx_ErrorBoundary_body {
        @mixin addBackdrop 85%, 5px, 20px;
    }

    // HomePage
    .mx_HomePage_default_wrapper {
        @mixin addBackdrop;
        padding-top: 20px;
        z-index: 1;
    }

    // User share
    .mx_UserViewShare_default_wrapper {
        @mixin addBackdrop 85%, 10px;
        padding: 20px;
        min-width: 400px;
    }

    .mx_GenericEventListSummary[data-layout="bubble"] {
        .mx_GenericEventListSummary_unstyledList {
            .mx_EventTile_info {
                .mx_EventTile_line > a {
                    left: -80px;
                }
            }
        }
    }

    .mx_RoomAvatarEvent_avatar {
        top: 1px;
    }

    // Whitelist IPs
    .ck_RoomInvalid_Container {
        @mixin addBackdrop;
        color: var(--cpd-color-text-primary);
        max-width: 600px;
        margin: 0 auto;
        padding: 20px;
        border-radius: 15px;
    }

    // System messages
    .mx_GenericEventListSummary[data-layout="bubble"],
    .mx_EventTile_info[data-layout="bubble"] {
        .mx_EventTile_line {
            // timestamp

            .mx_TextualEvent {
                background-color: var(--cpd-color-bg-subtle-secondary);
                color: var(--cpd-color-text-primary);
            }
        }
    }

    // System message grouped
    .mx_GenericEventListSummary[data-layout="bubble"] {
        margin-bottom: 10px;
        .mx_GenericEventListSummary_toggle {
            @mixin addBtnBackdrop;
        }
    }

    // Bubbles
    .mx_EventTile[data-layout="bubble"] {
        .mx_EventTile_avatar {
            border-width: 1px;
        }
        // User Avatar
        & > .mx_DisambiguatedProfile {
            @mixin addBackground;
            padding: 2px 5px;
            margin-bottom: -4px;
            border-radius: 10px;

            .mx_DisambiguatedProfile_displayName {
                margin-inline-end: 0;
            }
        }

        .mx_EventTile_receiptSending,
        .mx_EventTile_receiptSent,
        .ck_ReadReceiptGroup_button {
            background-color: $eventbubble-self-bg;
            border-radius: 50%;
            padding: 1px;

            &::before {
                top: 1px;
                left: 1px;
                background-color: var(--cpd-color-text-action-primary);
            }
        }

        .mx_ReadReceiptGroup {
            .mx_ReadReceiptGroup_remainder {
                @mixin addBackground;
                border-radius: 50%;
                padding: 0 2px;
            }
        }

        .ck_ReadReceiptGroup_button {
            position: relative;
            top: -1px;
        }

        .mx_ReactionsRow {
            .mx_ReactionsRow_showAll {
                @mixin addBtnBackdrop;
                margin-left: 10px;
            }
        }
    }

    // Selection
    .ck_RoomSelection_wrapper {
        @mixin addBackground;
    }

    // Connection status
    .mx_RoomView_statusArea {
        border-radius: 10px 10px 0 0;

        .mx_RoomStatusBar_connectionLostBar {
            padding-top: 10px;
        }

        .mx_RoomStatusBar_connectionLostBar_desc {
            padding-bottom: 10px;
        }
        .mx_RoomStatusBar_unsentMessages {
            > div[role="alert"] {
                background-color: unset;
            }
        }
    }

    // Typing
    .mx_WhoIsTypingTile {
        .mx_WhoIsTypingTile_label {
            @mixin addBackdrop;
            flex: 0 0 auto;
            padding: 4px 10px;
            color: var(--cpd-color-green-1000);
        }
    }

    // Search result
    .mx_RoomView_searchResultsPanel {
        .mx_RoomView_MessageList li > h2 {
            @mixin addRoundedBg;
        }
    }

    // Room preview
    .mx_RoomPreviewBar {
        @mixin addBackdrop 85%, 10px;
        padding: 20px;
    }
}

.mx_SearchBar {
    @mixin addBackground;
}

.ck_EventTilePreview_wrapper {
    position: relative;
    overflow: hidden;
}

// Chat background wallpaper
.ck_ChatBackground {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    .ck_ChatBackground_Item {
        // css tricks: remove border flash when apply blur effect
        width: calc(100% + 40px);
        height: calc(100% + 40px);
        margin-left: -20px;
        margin-top: -20px;

        background-position: center center;
        background-color: inherit;
        background-size: cover;
        &.blur {
            /*noinspection CssUnresolvedCustomProperty*/
            filter: blur(var(--blur-radius));
        }
    }
}

// Chat background Layout bubbles
.mx_LayoutSwitcher_RadioButton_preview {
    .ck_ChatBackground_Item {
        &.blur {
            /*noinspection CssUnresolvedCustomProperty*/
            filter: blur(var(--blur-radius));
        }
    }
}

// Chat background at HomePage
.ck_HomePage_button_openAppearance {
    &:before {
        /*noinspection CssUnknownTarget*/
        mask-image: url("$(res)/img/element-icons/settings/appearance.svg");
    }
}

// Chat background Setting panel
.ck_ChatBackground_panel {
    display: flex;
    align-items: center;
    justify-content: start;
    .ck_ChatBackground_current {
        display: inline-block;
        position: relative;
        overflow: hidden;
        width: 125px;
        height: 125px;
        border: solid 1px var(--cpd-color-gray-400);
    }
    .ck_ChatBackground_actions {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-evenly;
        padding-left: 20px;
        .ck_ChatBackground_uploadButton {
            display: flex;
            justify-content: start;
            .mx_Spinner {
                margin-left: 10px;
            }
        }
    }
}

// Chat background choose dialog
.ck_ChatBackground_chooseDialog {
    position: relative;
    max-height: 700px;
    overflow: hidden;
    .ck_ChatBackground_topActions {
        padding: 5px 2px;
        margin-bottom: 5px;
        .ck_ChatBackground_uploadButton {
            padding: 2px 3px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: stretch;
            .mx_Spinner {
                justify-content: start;
                margin-left: 18px;
            }
            svg {
                margin-right: 10px;
                width: 18px;
                height: 18px;
                fill: var(--cpd-color-text-action-accent);
            }

            &:hover {
                background-color: var(--cpd-color-gray-300);
            }
        }
    }
    .ck_ChatBackground_dialogFixedActions {
        position: absolute;
        background-color: var(--cpd-color-bg-subtle-secondary);
        padding: 10px 5px;
        bottom: 5px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        border-radius: 15px;
        box-shadow: 1px 3px 15px #3333334D;
        .mx_AccessibleButton {
            min-width: 50px;
            margin: 0 10px;
        }
    }
}

// Chat background preview dialog
.ck_ChatBackground_previewDialog {
    max-width: 400px;
    .ck_ChatBackground_previewImage {
        max-width: 350px;
        min-height: 300px;
        max-height: 500px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        border: solid 2px var(--cpd-color-gray-700);
        img {
            width: 350px;
            height: 500px;
            object-fit: cover;
        }
    }
    .ck_ChatBackground_previewActions {
        margin: 30px auto 0;
        text-align: center;
        .mx_AccessibleButton {
            margin: 0 10px;
        }
    }
}

// Chat background tile in choose dialog
.ck_ChatBackground_backgrounds {
    $backgroundWidth: 175px;
    $backgroundHeight: 250px;
    display: grid;
    grid: $backgroundHeight/ auto auto auto;
    grid-auto-rows: $backgroundHeight;
    gap: 5px;
    max-height: 550px;
    padding-bottom: 80px;
    overflow-y: scroll;
    overflow-x: hidden;
    & > div {
        box-sizing: border-box;
        position: relative;
        width: $backgroundWidth;
        height: $backgroundHeight;
        border: solid 1px var(--cpd-color-gray-400);
        .selected, .chose {
            position: absolute;
            display: inline-block;
            top: 5px;
            right: 5px;
            border-radius: 50%;
            width: 22px;
            height: 22px;
            border: solid 2px #fff;
            &::after {
                content: "";
                mask-image: url("@vector-im/compound-design-tokens/icons/check.svg");
                display: block;
                width: 18px;
                height: 18px;
                background-color: #fff;
                color: #fff;
                mask-size: 18px;
                mask-position: 2px 2px;
                mask-repeat: no-repeat;
            }
        }
        .chose {
            background-color: var(--cpd-color-green-ctalk);
        }
        .selected {
            background-color: var(--cpd-color-gray-700);
        }
        .none {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-16px, -16px);
            display: block;
            width: 32px;
            height: 32px;
            background-color: var(--cpd-color-text-placeholder);
            color: var(--cpd-color-text-placeholder);
            mask-size: 32px;
            mask-repeat: no-repeat;
            /*noinspection CssUnknownTarget*/
            mask-image: url("$(web_res)/themes/element/icons/none.svg")
        }
    }
    .ck_ChatBackground_deleteCustom {
        left: 10px;
    }
    .ck_ChatBackground_tip {
        position: absolute;
        bottom: 5px;
        color: #fff;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
        background-color: #2B2B2BE6;
        border: solid 1px var(--cpd-color-gray-400);
        border-radius: 18px;
        padding: 3px 10px;
        .mx_Checkbox label {
            white-space: nowrap;
        }

        &.media-error {
            width: 125px;
            text-align: center;
            bottom: 10px;
            border-radius: 5px;
        }
    }
    .mx_Slider {
        left: 50%;
        position: absolute;
        bottom: 45px;
        width: 90%;
        padding: 3px;
        background-color: #2828284D;
        border-radius: 20px;
        transform: translateX(-50%);
        input[type="range"] {
            display: block;
            font-size: 0.5em;
            width: calc(100% - 25px);
            margin: 0 auto;
            // overriding slider color
            --cpd-color-gray-400: #e1e6ec;
        }
        .mx_Slider_selection {
            display: none;
        }
    }
    .ck_ChatBackground_colorType {
        z-index: 1;
        .ck_ChatBackground_colorPicker {
            position: absolute;
            top: 250px;
            right: 3px;
            box-shadow: 2px -2px 22px #3333334D;
            & > div {
                box-shadow: 3px 6px 12px var(--cpd-color-gray-1000);
                &.body, input {
                    background: #fff;
                }
            }
        }
        .color-picker {
            position: relative;
            width: 4rem;
            font-family: monospace;
            height: 24px;
            line-height: 24px;
            display: inline-block;
            padding: 3px 0 3px 25px;
            &:after {
                content: "";
                position: absolute;
                display: inline-block;
                width: 16px;
                height: 16px;
                top: 50%;
                left: 5px;
                transform: translateY(-50%);
                /*noinspection CssUnresolvedCustomProperty*/
                background-color: var(--selected-color);
                border-radius: 50%;
                border: solid 1px var(--cpd-color-gray-400);
            }
        }
    }
    .ck_ChatBackground_patternType {
        position: relative;
        & > div, img {
            width: 100%;
            height: 100%;
        }
        :first-child {
            position: relative;
        }
        :not(:first-child) {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    .ck_ChatBackground_patternDark {
        background-color: var(--cpd-color-bg-canvas-default);
    }
    .ck_ChatBackground_patternGradient.dark {
        mix-blend-mode: overlay;
    }
    .ck_ChatBackground_patternImage {
        object-fit: cover;
    }

    .ck_ChatBackground_imageType {
        overflow: hidden;
        img {
            // css tricks when apply blur effect
            width: calc($backgroundWidth + 30px);
            height: calc($backgroundHeight + 30px);
            margin: -15px;
            object-fit: cover;
        }
        &.blur img {
            /*noinspection CssUnresolvedCustomProperty*/
            filter: blur(var(--blur-radius));
        }
    }
}

.ck_Appearance_advance {
    width: 100%;
    margin: 10px 0;
}

