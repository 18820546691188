.mx_RoomStatusBar_connectionLostBar {
    height: 100%;
    align-items: center;
    margin-top: unset !important;
    min-height: unset !important;
    svg {
        color: var(--cpd-color-text-critical-primary);
    }
}
.ck_reInviteIcon {
    svg {
        width: 24px;
        height: 24px;
    };
}

.ck_reInviteSpinner .mx_Spinner{
    align-items: center;
    display: flex;
    justify-content: flex-end;
}
