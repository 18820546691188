.ck_OTPBody {
    width: 500px;
    font-size: $font-15px;
    color: #61708b;
    background-color: #fff;
    padding: 25px 60px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
    border-radius: 10px;
}

.ck_loginContentField {
    text-align: center;
}

.ck_buttonSendOTP {
    text-align: center;
    font-size: $font-15px;
    color: var(--cpd-color-text-action-accent);
    cursor: pointer;
}

.ck_phoneBox{
    display: flex;
    justify-content: center;
    align-items: center;
}

.ck_buttonChangePhone {
    padding-left: 5px;
    cursor: pointer;
}

.ctalk-contentBox {
    padding-top: 15px;
}

.ck_Spinner {
    display: inline-block !important;
}

