.ck_view_response {
    &.mx_Dialog_fixedWidth {
        width: 600px;
    }
    .ck_view_response_container {
        .ck_response_item {
            padding: 1em 0;
        }
        .ck_response_item_file {
            background: var(--cpd-color-green-300);
            padding: 1em;
            border-radius: .8em;
            margin-top: 12px;
            .ck_response_file_text {
                color: var(--cpd-color-green-ctalk);
            }
            .ck_response_file_line {
                border-bottom: 1px solid var(--cpd-color-gray-400);
                padding: .5em 0;
            }
            .ck_response_file_item {
                padding: .5em 0;
                .ck_response_file_name {
                    color: var(--MessageTimestamp-color);
                    padding: .1em 0;
                    width: calc(100% - 1px - 1px);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .ck_response_file_note {
                    padding: .1em 0;
                    white-space: pre-line;
                }
                .mx_MFileBody_info_filename {
                    color: var(--cpd-color-green-ctalk);
                }
            }
        }
        .ck_response_item_content {
            background: var(--cpd-color-green-300);
            padding: 1em;
            border-radius: .8em;
            margin-top: .5em;
            white-space: pre-line;
            word-wrap: break-word;
        }
    }
    .ck_response_item_text {
        color: var(--cpd-color-text-primary);
        .ck_response_item_label {
            font-weight: bold;
        }
    }
}
