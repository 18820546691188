.mx_Autocomplete_Completion_block,
.ck_Autocomplete_Completion_pill {
    display: flex;
    .ck_Autocomplete_Completion_Box_Title_Non_Subtitle {
        width: 60%;
    }
    .ck_Autocomplete_Completion_Box_Title {
        width: 50%;
    }
    .ck_Autocomplete_Completion_Subtitle {
        width: 20%;
    }
    .ck_Autocomplete_Completion_Description_Non_Subtitle {
        width: 30%;
        min-width: unset;
    }
    .ck_Autocomplete_Completion_Description {
        width: 20%;
        min-width: unset;
    }
    .ck_Autocomplete_Completion_Wrap_Text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
