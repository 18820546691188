.ck_Submit_Progressing {
    .ck_Linear_Progress {
        padding: 1em 0;
        .MuiBox-root {
            .MuiLinearProgress-bar {
                background-color: var(--cpd-color-green-ctalk);
            }
            .MuiLinearProgress-root {
                background-color: var(--cpd-color-green-400);
            }
        }
    }
    .ck_Submit_Progressing_status {
        font-size: $font-18px;
    }
    .ck_Submit_Progressing_percent {
        color: var(--MessageTimestamp-color);
    }
}
