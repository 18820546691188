.ck_ForceUpdate_remaining  {
    text-align: center;
    padding: 0 10px;
    width: 100px;
    white-space: pre-wrap;
    line-height: 1.5;
}

.ck_ForceUpdate_UpdateNow {
    flex: 0 1 50%;
}

.ck_ForceUpdate_error {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ck_ForceUpdate_errorContent {
    text-align: center;
    * {
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
