.ck_NotificationBadge_mention_content {
    margin-right: 3px;
    background-color: $alert;
    width: $room-icon-badge-size;
    height: $room-icon-badge-size;
    line-height: 0.875rem;
    border-radius: 50%;
    font-size: 0.625rem;
    color: #fff;
    text-align: center;
    &::after {
        content: "@";
    }
}

.mx_DecoratedRoomAvatar,
.mx_RoomTile_badgeContainer {
    .mx_NotificationBadge {
        &.mx_NotificationBadge_visible {
            background-color: var(--cpd-color-notification-badge-ctalk);

            &:not(.ck_NotificationBadge_mention) {
                &.mx_NotificationBadge_highlighted {
                    background-color: $alert;
                }
            }

            &.mx_NotificationBadge_dot {
                background-color: var(--cpd-color-text-primary);
            }
        }
    }
}

.mx_NotificationBadge {
    &.mx_NotificationBadge_visible {
        background-color: var(--cpd-color-notification-badge-ctalk);
    }
}

.mx_DecoratedRoomAvatar,
.mx_ExtraTile {
    .mx_NotificationBadge,
    .mx_RoomTile_badgeContainer {
        position: absolute;
        top: unset;
        bottom: 0;
        right: 0;
        height: $font-16px;
        min-width: $font-16px;
    }
    .ck_NotificationBadge_mention_content {
        margin-right: unset;
        position: absolute;
        top: 0;
        right: 0;
    }
}
