.mx_MessageContextMenu {
    background-color: inherit;
    border-radius: inherit;
}
.mx_IconizedContextMenu {
    .mx_IconizedContextMenu_optionList:nth-child(2) {
        border-top: none;
    }
}
.ck_MenuReactions_container {
    position: absolute;
    width: 330px;
    top: -60px;
    background-color: inherit;
    box-shadow: 5px -5px 10px 0 rgba(118, 131, 156, 0.3);
    border-radius: inherit;
    border: 1px solid var(--cpd-color-border-interactive-secondary);

    &[data-mx-theme="dark"] {
        box-shadow: 5px -5px 10px 0 #15191E;
    }
}
.ck_MenuReactions_wrapper {
    padding: 5px 10px 8px;
    margin: 0;
    .mx_MessageContextMenu_iconReact {
        &::before {
            width: 24px;
            height: 24px;
        }
    }
    .mx_EmojiPicker_item {
        svg {
            display: inline-block;
            height: 24px;
            top: 4px;
            position: relative;
        }
    }
}
