.mx_CompleteSecurityBody {
    width: unset;
    max-width: 700px;
}
.mx_AccessSecretStorageDialog_primaryContainer {
    .ck_boxRecoverKey {
        align-items: center;
        display: flex;
        justify-content: space-between;
        .ck_inputRecoverKey {
            width: 80%;
        }
    }
}

.ck_typeLoginContainer {
    display: flex;
    padding-top: 0.5em;
}

.ck_appleLoginContainer {
    display: flex;
    padding-top: 1em;
    justify-content: center;
}

.ck_resetContainer {
    padding-top: 1em;
    .ck_resetButton {
        color: var(--cpd-color-text-critical-primary);
        font-weight: bold;
    }
}
.mx_CompleteSecurity_header {
    justify-content: center;
}
