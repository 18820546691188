.ck_PageLocked_wrapper {
    background-color: #03281e;
    /*noinspection CssUnknownTarget*/
    background-image: url('$(web_res)/themes/element/icons/bg-ctalkto.svg');
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center -50px;
    height: 100%;
    width: 100%;
    font-size: $font-14px;
    padding: 120px 0 0 0;
    margin: 0;

    @media screen and (max-width: 500px) {
        background-image: none;
        background-color: var(--cpd-color-bg-canvas-default);
    }

    .ck_PageLocked {
        &_card {
            margin: 0 auto;
            max-width: 480px;
            width: 100%;
            background-color: var(--cpd-color-bg-canvas-default);
            border-radius: 16px;
            box-shadow: 0 18px 24px rgba(0, 0, 0, 0.06);
            padding: 2rem;
            text-align: center;
            @media screen and (max-width: 500px) {
                box-shadow: none;
            }
        }
        &_logo {
            margin-bottom: 32px;
            img {
                width: 79px;
            }
        }
    }
}
