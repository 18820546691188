.ck_RoomInvalid_Container {
    z-index: 1;
    text-align: center;
    h1 {
        font-size: 1.2rem;
        font-weight: 500;
    }
    .ck_RoomInvalid_Icon_Lock {
        display: inline-block;
        justify-content: center;
        height: 125px;
        width: 125px;
        svg path {
            stroke: var(--cpd-color-text-primary);
            stroke-width: 1;
        }
    }

    .ck_AccessibleButton_Reject {
        padding-top: 10px;
    }
}
