.mx_RoomTile.ck_RoomTile:not(.ck_room_detail) {
    margin-bottom: unset;
    padding: 0 4px;

    min-height: 69px;

    .ck_favourite_icon {
        height: 13px;
    }

    &:last-child {
        margin-bottom: 1px;
    }

    &:hover,
    &:focus-within,
    &.mx_RoomTile_hasMenuOpen {
        border-radius: unset;
        &:not(.mx_RoomTile_selected) {
            background-color: $panel-actions;
        }
        .ck_RoomTile_title_name_wrapper {
            .ck_favourite_icon {
                display: none;
            }
        }
        .ck_favourite_icon {
            display: unset;
        }
    }
    .ck_RoomTile_subtitle_container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 100%;
    }
    .ck_AutoDeleteMessage_badge {
        position: absolute;
        bottom: 10px;
        left: 37px;
        width: $room-icon-badge-size;
        height: $room-icon-badge-size;
        line-height: $room-icon-badge-size;
        font-size: 0.4rem;
        z-index: 0;
        background-color: var(--cpd-color-gray-700);
    }
    .ck_AutoDeleteMessage_badgeCount {
        color: #ffffff;
    }
    &.mx_RoomTile_selected {
        border-radius: unset;
        background-color: $roomtile-active-bg-color;

        .mx_RoomTile_title,
        .mx_RoomTile_subtitle_text,
        .ck_RoomTile_subtitle_sender,
        .ck_MessageTimestamp {
            color: $roomtile-active-color;
        }
        .mx_LiveContentSummary_text {
            color: $roomtile-active-color;

            &::before {
                background-color: $roomtile-active-color;
            }
        }

        .ck_RoomTile_group_icon,
        .ck_RoomTile_server_notice_icon,
        .ck_favourite_icon {
            background: $roomtile-active-color;
        }

        .mx_RoomTile_subtitle_text {
            opacity: 0.7;
        }

        .mx_RoomTile_menuButton,
        .mx_RoomTile_notificationsButton {
            &::before {
                background: $roomtile-active-color;
            }
        }

        .ck_AutoDeleteMessage_roomMarker {
            &:after {
                outline: dashed 2px $roomtile-active-color;
            }
        }

        .mx_DecoratedRoomAvatar,
        .mx_RoomTile_badgeContainer {
            .mx_DecoratedRoomAvatar_icon_globe {
                &::before {
                    background-color: $roomtile-active-color;
                }
            }
            .mx_DecoratedRoomAvatar_icon_online,
            .mx_DecoratedRoomAvatar_icon_offline {
                &::before {
                    border: solid 2px $roomtile-active-color;
                    width: 0.8125rem;
                    height: 0.8125rem;
                }
            }
            .mx_NotificationBadge {
                &.mx_NotificationBadge_level_highlight {
                    background-color: var(--cpd-color-icon-critical-primary);
                }
                &.mx_NotificationBadge_visible {
                    background-color: $roomtile-active-color;
                }
                .mx_NotificationBadge_count {
                    color: var(--cpd-color-notification-badge-ctalk);
                }
            }
        }
    }

    &:not(.mx_RoomTile_selected) {
        .ck_RoomTile_subtitle_sender {
            color: var(--cpd-color-text-primary);
        }

        .mx_RoomTile_subtitle_text {
            color: var(--cpd-color-gray-800);
        }
    }

    .mx_DecoratedRoomAvatar,
    .mx_RoomTile_avatarContainer {
        display: flex;
        align-items: center;
        padding-left: 4px;

        .mx_DecoratedRoomAvatar_icon {
            position: absolute;
            bottom: 13.25%;
            right: 1.25%;
            width: 25%;
            height: 25%;
            border-radius: 50%;
        }

        .mx_DecoratedRoomAvatar_icon::before {
            content: "";
            width: $room-icon-badge-size;
            height: $room-icon-badge-size;
            right: 0;
            position: absolute;
            border-radius: 50%;
        }
    }

    .ck_RoomTile_container {
        display: flex;
        width: 100%;
        border-bottom: 1px solid var(--cpd-color-alpha-gray-400);
        overflow: hidden;

    }

    .mx_RoomTile_titleContainer {
        height: 68px;
        margin-right: unset;
        &.ck_RoomTile_no_subtitle {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            align-items: center;
        }

        .ck_RoomTile_title_name_wrapper {
            max-width: calc(100% - 20px);
            display: flex;
            justify-content: space-between;
            min-height: 22px;
            .ck_favourite_icon {
                padding-top: 2px;
            }
        }

        .mx_RoomTile_title {
            font: var(--cpd-font-body-md-regular);
            font-weight: var(--cpd-font-weight-medium);
            line-height: 1.25;
            padding-bottom: 3px;
            display: flex;
            align-items: center;

            &.mx_RoomTile_titleHasUnreadEvents {
                font-weight: var(--cpd-font-weight-medium);
            }

            &:has(.ck_RoomTile_group_icon, .ck_RoomTile_server_notice_icon) {
                display: flex;
                align-items: center;
            }

            .ck_RoomTile_title_name {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .mx_RoomTile_subtitle {
            max-width: calc(100% - 37px);
            top: unset;
            column-gap: unset;

            .ck_RoomTile_subtitleContainer {
                display: flex;
                flex-direction: column;
                overflow: hidden;
                font-size: 12px;
                row-gap: 3px;
                width: 100%;

                .ck_RoomTile_subtitle_sender {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .ck_RoomTile_subtitle_wrapper_text {
                    display: flex;
                    justify-content: space-between;
                }
            }

            .ck_RoomTile_subtitle_badgeContainer {
                height: 0;
                &.ck_RoomTile_subtitle_badgeContainer_dm {
                    height: 100%;
                }
            }
        }
    }

    &.mx_RoomTile_minimized {
        padding: 6px;

        .ck_RoomTile_container {
            border-bottom: unset;
        }

        .mx_DecoratedRoomAvatar,
        .mx_RoomTile_avatarContainer {
            padding-left: unset;
            .mx_DecoratedRoomAvatar_icon {
                bottom: 5px;
                right: 1px;
            }
        }
        .mx_NotificationBadge_visible {
            bottom: 1px;
            right: 1px;
            min-width: $room-icon-badge-size;
            height: $room-icon-badge-size;
            .mx_NotificationBadge_count {
                line-height: $room-icon-badge-size;
            }

            &.mx_NotificationBadge_dot {
                width: $room-icon-badge-size;
                height: $room-icon-badge-size;
                background-color: $roomlist-bg-color;
                &:after {
                    content: "";
                    display: block;
                    width: calc($room-icon-badge-size / 2);
                    height: calc($room-icon-badge-size / 2);
                    border-radius: 50%;
                    background-color: var(--cpd-color-text-primary);
                    position: absolute;
                    bottom: 4px;
                    right: 4px;
                }
            }
        }
        .ck_AutoDeleteMessage_badge {
            bottom: 1px;
            left: 33px;
        }
    }

    &:not(.mx_RoomTile_minimized, .mx_RoomTile_sticky)&:has(.ck_RoomTile_subtitle_wrapper_text) {
        &:hover,
        &:focus-within,
        &.mx_RoomTile_hasMenuOpen {
            .mx_RoomTile_badgeContainer {
                width: unset;
                height: 16px;
                display: flex;
            }

            .mx_RoomTile_menuButton {
                display: none;
            }
        }
    }
}

.mx_RoomTile {
    .ck_favourite_icon {
        height: unset;
    }
    &:hover,
    &:focus-within,
    &.mx_RoomTile_hasMenuOpen {
        &:not(.ck_RoomTile_title_name_wrapper) {
            .ck_favourite_icon {
                display: none;
            }
        }
    }
}

.ck_RoomTile_group_icon {
    margin-right: 2px;
    min-width: 13px;
    height: 13px;
    left: 0;
    /*noinspection CssUnknownTarget*/
    mask-image: url('$(web_res)/themes/element/icons/group.svg');
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: 13px;
    background: var(--cpd-color-text-action-primary);
}

.ck_RoomTile_server_notice_icon {
    margin-right: 2px;
    min-width: 13px;
    height: 13px;
    left: 0;
    /*noinspection CssUnknownTarget*/
    mask-image: url('$(web_res)/themes/element/icons/chat-folder-icons/commercial.svg');
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: 13px;
    background: var(--cpd-color-text-action-primary);
}

.ck_favourite_icon {
    margin-right: 2px;
    min-width: 13px;
    height: 13px;
    left: 0;
    /*noinspection CssUnknownTarget*/
    mask-image: url('$(web_res)/themes/element/icons/chat-folder-icons/star.svg');
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: 13px;
    background: var(--cpd-color-gray-800);
}

.mx_RoomTile_contextMenu, .mx_ContextualMenu {
    .ck_RoomTile_iconAddToFolder::before {
        /*noinspection CssUnknownTarget*/
        mask-image: url("$(web_res)/themes/element/icons/add-to-folder.svg");
        mask-size: 20px;
    }
}

.ck_MessageTimestamp {
    /*noinspection CssUnresolvedCustomProperty*/
    color: var(--MessageTimestamp-color);
    font-size: 0.7rem;
    font-feature-settings: "tnum";
    font-variant-numeric: tabular-nums;
    display: block;
    white-space: nowrap;
    padding-bottom: 4px;
}
