.ck_ChangeComposerDialog {
    .ck_Dialog_content {
        padding: 0 4em;
        color: var(--cpd-color-text-primary);
        .ck_ChangeComposerDialog_image {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
        }

        .ck_ChangeComposerDialog_image img {
            max-width: 100%;
            height: auto;
            border-radius: 8px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        }
        .ck_ChangeComposerDialog_toggle {
            .mx_ToggleSwitch {
                 border: unset;
                 background-color: $togglesw-off-color;
             }
            .mx_ToggleSwitch_ball {
                background-color: #ffffff;
            }
            .mx_ToggleSwitch_on {
                background-color: var(--cpd-color-text-action-accent);
            }
        }
        .ck_ChangeComposerDialog_notice {
            margin: 20px 0;
        }
    }
    .ck_ChangeComposerDialog_TitleContainer {
        display: flex;
        align-items: flex-start;
        .ck_ChangeComposerDialog_notice_title {
            color: var(--cpd-color-text-critical-primary);
        }
        .ck_ChangeComposerDialog_Title {
            margin: 0;
        }
        .ck_ChangeComposerDialog_BetaLabel {
            background-color: var(--cpd-color-text-action-accent);
            color: var(--cpd-color-bg-canvas-default);
            padding: 4px 8px;
            border-radius: 4px;
            margin-top: -13px;
            font-size: 12px;
        }
    }
}
